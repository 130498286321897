import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import Layout from '@codedrop/layout/esm/layout';
import Block from '@codedrop/block/esm/block';
import Block2Col from '@codedrop/block-2-col/esm/block-2-col';
import Team from '@codedrop/team/esm/team';
import Customers from '@codedrop/customers/esm/customers';
import SimpleCta from '@codedrop/cta/esm/simple-cta';
import Footer from '@codedrop/footer/esm/footer';
import setHTML from '@codedrop/utils/esm/utils';
import data from '../data/skpr.json';
import whySkpr from '../data/why-skpr.json';
import HowItWorksAnimation from '../components/how-it-works/how-it-works-animation';

const { seo, header, customers, footer, ctaForm } = data;
const {
  site,
  pageTitle,
  intro,
  cta,
  efficiency,
  security,
  budget,
  reliability,
  supported,
  team,
  teamIntro,
} = whySkpr;
seo.description = site.description;
cta.modalContent = ctaForm;

const WhySkprPage = () => {
  const [animationStage, setAnimationStage] = useState('0');

  useEffect(() => {
    const stages = ['0', '1', '2'];
    let animationIndex = 0;
    const interval = setInterval(() => {
      if (stages[animationIndex] === '2') {
        return;
      }
      animationIndex += 1;
      setAnimationStage(stages[animationIndex]);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <SEO title={site.title} seo={seo} />
      <Header header={header} />
      <main>
        <Layout className="background--firework section--top-l">
          <Block
            title={{ tag: 'h1', text: pageTitle }}
            subtitle={{ tag: 'h2', ...intro.title }}
            modifier="hero"
          >
            <p className="text--l">{setHTML(intro.content)}</p>
          </Block>
          <div className="divider" />
          {customers && (
            <>
              <Customers customers={customers} />
              <div className="divider" />
            </>
          )}
        </Layout>
        {efficiency && (
          <Layout className="section--top-l">
            <div className="block-graphic grid">
              <div className="grid__col grid--6-col">
                <Block
                  title={{ tag: 'h2', text: efficiency.title }}
                  subtitle={{ tag: 'h3', text: efficiency.subtitle }}
                >
                  <div className="text--s">{setHTML(efficiency.content)}</div>
                  <Link className="button" to={efficiency.button.link}>
                    {efficiency.button.text}
                  </Link>
                </Block>
              </div>
              <div className="grid__col grid--6-col how-it-works__animation">
                <HowItWorksAnimation
                  animid={'desktop'}
                  currentAnimationIsAbove={false}
                  currentAnimationStage={animationStage}
                />
              </div>
            </div>
          </Layout>
        )}
        {reliability && security && (
          <Block2Col
            firstCol={
              <Block
                title={{ tag: 'h2', text: reliability.title }}
                subtitle={{ tag: 'h3', text: reliability.subtitle }}
              >
                <div className="text--s">{setHTML(reliability.content)}</div>
                <Link className="button" to={reliability.button.link}>
                  {reliability.button.text}
                </Link>
              </Block>
            }
            secondCol={
              <Block
                title={{ tag: 'h2', text: security.title }}
                subtitle={{ tag: 'h3', text: security.subtitle }}
              >
                <div className="text--s">{setHTML(security.content)}</div>
                <a className="button" href={security.button.link}>
                  {security.button.text}
                </a>
              </Block>
            }
          />
        )}
        {supported && budget && (
          <Block2Col
            variant="offset"
            firstCol={
              <Block
                title={{ tag: 'h2', text: supported.title }}
                subtitle={{ tag: 'h3', text: supported.subtitle }}
              >
                <div className="text--s">{setHTML(supported.content)}</div>
              </Block>
            }
            secondCol={
              <Block
                title={{ tag: 'h2', text: budget.title }}
                subtitle={{ tag: 'h3', text: budget.subtitle }}
              >
                <div className="text--s">{setHTML(budget.content)}</div>
              </Block>
            }
          />
        )}
        {team && <Team intro={teamIntro} people={team} />}
        <SimpleCta cta={cta} />
      </main>
      <Footer footer={footer} />
    </div>
  );
};

export default WhySkprPage;
